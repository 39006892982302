import { RouteComponentProps } from '@reach/router'
import axios from 'axios'
import { withPrefix } from 'gatsby'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Modal from 'react-modal'
import { StyledImage, StyledRow } from '../../components/RestaurantsBar/style'
import { RequestGetter } from '../../helpers/request'
import withLocation from '../AuthSection/withLocation'
import { Chef } from '../Chef/chef'
import { HelmetComponent } from '../HelmetComponent'
import Loading from '../Loading'
import { Close, FilterButton, ModalTitleContainer } from '../RestaurantsSection/style'
import ChefCard from './components/ChefCard'
import ChefFilters from './components/ChefFilters'
import { ChefSectionContainer } from './style'

const LoadingImg = require('../../assets/images/general/loading.svg')
const chefsAtHomeImage = require('../../assets/images/general/chefs-at-home.png')

const customStyles = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: 101,
    padding: '0 0 10rem 0',
    backgroundColor: '#242424',
  },
}

const customStylesLoading = {
  content: {
    ...customStyles.content,
    backgroundColor: 'transparent',
    border: 'none',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
  },
}

class ChefSection extends Component<RouteComponentProps, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      loading: true,
      isFiltering: true,
      chefs: [],
      specialties: [],
      filters: [],
      modalIsOpen: false,
    }
  }

  componentDidMount() {
    const { search } = this.props
    this.getSpecialties()
    if (Object.entries(search).length !== 0 && search.constructor !== Object) {
      this.searchByUrlParams(search)
      return
    }
    this.getChefs()
  }

  getSpecialties = () => {
    RequestGetter('get', 'chefs/specialties').then((data: any) => {
      this.setState({ specialties: data.data })
    })
  }

  getChefs = () => {
    axios
      .get(`${process.env.API_URL}chefs`, {
        headers: {},
        params: { per_page: 500 },
      })
      .then((response: any) => {
        this.setState({ chefs: response.data.data, loading: false, isFiltering: false })
      })
  }

  openModal = () => {
    this.setState({ modalIsOpen: true })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false })
  }

  applyFilters = (specialtyName: string, isChecked: boolean) => {
    const { filters } = this.state
    if (!filters.includes(specialtyName)) {
      filters.push(specialtyName)
    }
    if (isChecked) {
      this.setState({ filters, isFiltering: true }, () => {
        this.search()
      })
      return
    }
    this.setState(
      { filters: filters.filter((option: string) => option != specialtyName), isFiltering: true },
      () => {
        this.search()
      }
    )
  }

  search = () => {
    const { filters } = this.state
    axios
      .get(`${process.env.API_URL}chefs`, {
        headers: {},
        params: { filters, per_page: 500 },
      })
      .then((data: any) => data.data)
      .then((response: any) =>
        this.setState({ chefs: response.data, isFiltering: false, loading: false }, () => {
          history.pushState({}, 'Results for chefs', this.generateUrlParams())
        })
      )
  }

  generateUrlParams = (): string => {
    const { filters } = this.state
    let urlParams = ''
    let urlFilters = ''
    if (filters && filters.length > 0) {
      urlFilters = `filters=${filters.join()}`
    }
    urlParams = [urlFilters].filter(Boolean).join('&')
    return withPrefix(`/chefs?${urlParams}`)
  }

  searchByUrlParams = (search: any) => {
    let filters: any[] = search.filters ? search.filters.split(',') : []
    let capitalizedFilters = filters.map(
      (filter) => filter.charAt(0).toUpperCase() + filter.slice(1)
    )
    this.setState(
      {
        filters: capitalizedFilters,
        isFiltering: true,
      },
      () => this.search()
    )
  }

  render() {
    const { loading, chefs, specialties, filters, modalIsOpen, isFiltering } = this.state

    return loading === true ? (
      <Loading />
    ) : (
      <ChefSectionContainer>
        <HelmetComponent 
          title="Chefs en Casa - Grande Table"
          description="Contrata a los mejores chefs del país y disfruta de cerca de una experiencia gastronómica privada en casa."
        />
        <StyledRow>
          <div className="container centered main-image">
            <StyledImage src={chefsAtHomeImage} />
          </div>
        </StyledRow>
        <div className="banner sf-pro-text-regular">
          <div className="container ">
            <div className="first-b text-uppercase padding-bottom-20 centered-text">
              <p className="font-size-15 reg-text">Vive lo mejor de la</p>
              <h2 className="sf-pro-text-semi-bold">Gastronomía</h2>
              <p className="font-size-15 colored sf-pro-text-semi-bold">en tu hogar</p>
            </div>
            <ul className="second-b font-size-15">
              <p className="font-size-15 roboto-bold centered-text padding-bottom-10 text-uppercase question">
                ¿Cómo funciona?
              </p>
              <li>Elige tu chef o tipo de cocina favoritos.</li>
              <li>Cuéntanos la temática de tu evento.</li>
              <li>¡Listo! Vive una nueva dimensión gastronómica en casa.</li>
            </ul>
          </div>
        </div>
        <div className="list-container">
          <div className="container padding-top-bottom-45">
            <div className="roboto-bold filter">
              <div className="container-btn-modal">
                <i className="icon-grande-table-20" />
                <div className="filter-btn-txt" onClick={this.openModal}>
                  Mostrar Filtros
                </div>
              </div>
            </div>
            <div className="filter-options">
              <ChefFilters
                applyFilters={this.applyFilters}
                specialties={specialties}
                filters={filters}
                isModal={false}
              />
            </div>
            <div className="chef-list">
              <div className="row center">
                {chefs.map((chef: Chef, index: number) => (
                  <ChefCard key={index} chef={chef} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <Modal style={customStylesLoading} isOpen={isFiltering == true}>
          <img src={LoadingImg} />
        </Modal>
        <Modal isOpen={modalIsOpen} onRequestClose={this.closeModal} style={customStyles}>
          <Close className="icon-grande-table-21" onClick={this.closeModal} />
          <div className="container-fluid">
            <div className="row">
              <ModalTitleContainer className="col-md-12">Filtrar</ModalTitleContainer>
            </div>
            <div className="container" style={{ paddingBottom: '20px' }}>
              <div className="col-md-12">
                <ChefFilters
                  applyFilters={this.applyFilters}
                  specialties={specialties}
                  filters={filters}
                  isModal={true}
                />
              </div>
            </div>
            <div className="container">
              <div className="col-md-12">
                <FilterButton onClick={this.closeModal}>Aplicar Filtros</FilterButton>
              </div>
            </div>
          </div>
        </Modal>
      </ChefSectionContainer>
    )
  }
}
ChefSection.propTypes = {
  search: PropTypes.object,
}

export default withLocation(ChefSection)
