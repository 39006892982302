import styled from 'styled-components'
import media from '../../styles/media'
import theme from '../../styles/theme'

export const ChefContainer = styled.div`
  &.hide {
    display: none;
  }

  .title-name {
    padding-bottom: 20px;
    font-family: DroidSerif;
    font-size: 30px;
    text-align: center;
    color: #c59d5f;
  }

  .roboto-bold {
    font-family: 'Roboto Bold';
  }
  .roboto-light {
    font-family: 'Roboto Light';
  }
  .roboto-medium {
    font-family: 'Roboto Medium';
  }
  .roboto-thin {
    font-family: 'Roboto Thin';
  }
  .roboto {
    font-family: 'Roboto';
  }

  .text {
    font-size: 13px;
    letter-spacing: 1px;
    color: ${theme.purpleBrown};
    white-space: pre-wrap;
  }

  .font-11 {
    font-size: 11px;
  }

  .font-14 {
    font-size: 14px;
  }

  .font-18 {
    font-size: 18px;
  }

  .text-color-black {
    color: #000000;
  }

  .justify-text {
    text-align: justify;
  }

  .padding-bottom-20 {
    padding-bottom: 20px;
  }

  .options-title {
    font-weight: bold;
    letter-spacing: 1.08px;
  }

  .colored {
    color: #c59d5f;
  }

  .thin-line {
    margin-bottom: 20px;
    height: 1px;
    border: none;
    background-color: #dfdfdf;
  }

  .padding-20 {
    padding: 20px;
  }

  .padding-10-20-10-0 {
    padding: 10px 20px 10px 0px;
  }

  .padding-10-20 {
    padding: 10px 20px;
  }

  .padding-bottom-10 {
    padding-bottom: 10px;
  }

  .centered-text {
    text-align: center;
  }

  .option-container {
    margin-bottom: 20px;
    border: solid 1px #dfdfdf;
    .options-submenu {
      font-weight: 500;
      text-transform: uppercase;
    }

    .italic {
      padding-bottom: 10px;
      font-style: italic;
    }

    .cost {
      letter-spacing: 1.38px;
    }

    .margin-width {
      margin: 10px auto 0 auto;
      width: 50%;
    }

    .black-border {
      padding: 0.5rem 0.2rem;
      background: none;
      font-family: 'San Francisco Display';
      font-size: 11px;
      border: solid 1px #000000;
    }

    ${media.sm`
    .coverages-restrictions {
      display: inline-block;
      width: 70%;
      vertical-align: middle;
    }
    .roboto.text.colored.centered-text {
      display: inline-block;
      width: 30%;
      vertical-align: middle;
      .margin-width {
        width: 100%;
      }
      .black-border {
        padding: 0.7rem 1.5rem;
      }
    }
    `}
  }

  .background-dark-gray {
    background-color: #cacaca;
  }

  .background-light-gray {
    background-color: #f0f0f0;
  }

  ul {
    margin: 0;
    list-style: none;
    li {
      margin-left: 0;
      margin-bottom: 10px;
      padding: 5px 0;
      .item {
        i {
          padding-right: 8px;
        }
        .value {
          padding-left: 28px;
          width: 100%;
          .restaurant-link {
            padding-right: 15px;
            color: inherit;
            text-decoration: underline;
            ${media.sm`
            display: inline-block;
            `}
          }
        }
        .capitalize {
          text-transform: capitalize;
        }
      }
    }
  }

  .faq-list {
    margin-left: -15px;
    .question-title {
      padding-right: 20px;
      max-width: 100%;
      cursor: pointer;
      .question-icon-float-right {
        float: right;
      }
    }
    .displayed {
      display: block;
    }
    .hide {
      display: none;
    }
    .thin-line {
      margin-bottom: 5px;
      margin-right: 20px;
    }
  }

  .dot {
    margin-right: 9px;
    height: 10px;
    width: 10px;
    background-color: #ccc;
    border-radius: 50%;
    display: inline-block;
  }

  .react-datepicker {
    width: 100%;
    border: none;
    background-color: transparent;
    .react-datepicker__month-container {
      float: none;
      .react-datepicker__header {
        background-color: transparent;
        border: none;
        .react-datepicker__current-month {
          padding-bottom: 15px;
          cursor: default;
          font-family: DroidSerif;
          font-size: 18px;
          font-weight: normal;

          span {
            color: #c59d5f;
          }
          i {
            color: ${theme.purpleBrown};
            &.decrease {
              float: left;
            }
            &.increase {
              float: right;
            }
          }
        }
        .react-datepicker__day-names {
          .react-datepicker__day-name {
            margin: 0 10px;
            font-family: 'Roboto Medium';
            font-size: 13px;
            color: ${theme.purpleBrown};
            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
            ${media.sm`
            margin: 0 2px;
            `}
            ${media.md`
            margin: 0 9px;
            `}
            ${media.lg`
            margin: 0 0.35rem;
            `}
            @media screen and (min-width: 1400px) {
              margin: 0 0.55rem;
            }
          }
        }
      }
      .react-datepicker__month {
        margin-left: 0;
        .react-datepicker__week {
          .react-datepicker__day {
            cursor: default;
            margin: 0 10px;
            font-family: 'Roboto Light';
            font-size: 13px;
            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
            ${media.sm`
            margin: 0 2px;
            `}
            ${media.md`
            margin: 0 9px;
            `}
            ${media.lg`
            margin: 0 0.35rem;
            `}
            @media screen and (min-width: 1400px) {
              margin: 0 0.55rem;
            }
          }
          .react-datepicker__day--today {
            border-radius: 50%;
          }
          .react-datepicker__day--selected:hover {
            background: #c59d5f;
          }
        }
      }
    }
  }

  .slick-slider {
    .slick-arrow {
      width: 64px;
      height: 64px;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px #f2f2f2;
      background: #ffffff;
      position: relative;
      i {
        position: absolute;
        color: ${theme.purpleBrown};
        top: 20px;
        left: 18px;
      }
      &.slick-prev {
        display: block;
        position: absolute;
        z-index: 98;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
      &.slick-next {
        display: block;
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .mobile {
    ${media.sm`
    display: none;
    `}
  }

  .web {
    @media screen and (max-width: 767px) {
      display: none;
    }
    ${media.sm`
    display: block;
    `}
  }

  ${media.sm`
  .left-panel {
    display: inline-block;
    width:70%;
    vertical-align: top;
    padding-right: 25px;
  }

  .right-panel {
    display: inline-block;
    width: 30%;
    vertical-align: top;
    .web {
      padding-top: 40px;
    }
  }

  .picture-secondblock {
    padding-top: 40px;
    vertical-align: top;
    .secondblock {
      display: inline-block;
      width: 60%;
      .title-name {
        text-align: left;
      }
    }
  }
  `}

  ${media.md`
  .picture-secondblock {
    .secondblock {
      width: 70%;
    }
  }
  `}

  ${media.lg`
  .left-panel {
    width:79%;
  }

  .right-panel {
    width: 21%;
  }

  .picture-secondblock {
    .secondblock {
      width: 75%;
    }
  }
  `}
`

export const Picture = styled.div`
  padding: 20px;
  text-align: center;
  &.padding-bottom-20 {
    padding: 0 0 20px;
  }
  .image-cropper {
    margin: 0 auto;
    width: 130px;
    height: 130px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    &.rectangle {
      border-radius: unset;
      overflow: unset;
      .margin-0 {
        margin: 0;
      }
    }
  }
  img {
    display: inline;
    margin: 0 auto;
    margin-left: -25%;
    height: 100%;
    width: auto;
  }
  ${media.sm`
  display: inline-block;
  vertical-align: top;
  width: 40%;
  `}
  ${media.md`
  width: 30%;
  `}
  ${media.lg`
  width: 25%;
  `}
`

export const SuggestionContainer = styled.div`
  padding: 10px;
`

export const Shadow = styled.div`
  position: absolute;
  background-color: transparent;
  height: 100%;
  width: 100%;
`

type Props = {
  image?: string
}

export const ImageContainer = styled.div`
  background-image: url(${(props: Props) => (props.image ? props.image : '')});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 0;
  border-radius: 10%;
  cursor: grab;
  height: 190px;
  width: 165px !important;
  position: relative;
`

export const BookingContainer = styled.div`
  &.hide {
    display: none;
  }
`
