import React from 'react'

interface PipedStringProps {
  pipedString: string
}

export const PipeReplacer = (props: PipedStringProps) => {
  if (props.pipedString.indexOf('|') > -1) {
    return props.pipedString.replace('|', '<br/>')
  }
  return props.pipedString
}
