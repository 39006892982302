import React, { Component } from 'react'
import axios from 'axios'
import DatePicker, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
import Select from 'react-select'
import * as NumericInput from 'react-numeric-input'
import { StyledError } from '../../Collapse/stepped/style'
import { NumericInputContainer } from '../../Generics/IncreaseDecreaseNumber/style'
import Button from '../../Button'
import Steps from './steps'
import { Reservation, ReservationType } from '../chef'
import { GtmEventsLoad } from '../../../helpers/gtm'
import { ChefEvent } from '../../../helpers/GTM/ChefEvent'

const dateSyle = require('react-datepicker/dist/react-datepicker.css')

class Step1 extends Component<
  {
    logo: string
    reservation: Reservation
    bookedDates: [Reservation]
    disableDays: number
    onBackButtonClicked: any
    onNextButtonClicked: any
  },
  any
> {
  constructor(props: any) {
    super(props)
    this.state = {
      reservationTypes: [],
      fields: {},
      errors: {},
      currentBookedDates: [],
      currentDate: new Date(),
    }
  }

  componentDidMount() {
    this.getReservationTypes()
    this.disableDays()
    this.getBookedDates()
    this.setInputValues()
    window.scrollTo(0, 0)
  }

  getReservationTypes = () =>
    axios
      .get(`${process.env.API_URL}chefs/reservation-types`)
      .then((data: any) => data.data)
      .then((response: any) =>
        this.setState({
          reservationTypes: [].concat
            .apply([], response)
            .map((reservationType: ReservationType) => ({
              value: reservationType.id,
              label: reservationType.name,
            })),
        })
      )

  disableDays = () => {
    const { currentDate } = this.state
    currentDate.setDate(currentDate.getDate() + this.props.disableDays)
    this.setState({ currentDate })
  }

  getBookedDates = () =>
    this.setState({
      currentBookedDates: this.props.bookedDates.map(
        (bookedDate: Reservation) => new Date(bookedDate.reserved_at)
      ),
    })

  setInputValues = () => {
    const { reservation } = this.props
    const { fields } = this.state
    if (reservation) {
      fields['startDate'] = reservation.reserved_at ? new Date(reservation.reserved_at) : null
      fields['hour'] = reservation.reserved_at ? new Date(reservation.reserved_at) : null
      fields['type'] = reservation.type
        ? { value: reservation.type.id, label: reservation.type.name }
        : null
      fields['adults'] = reservation.adults || reservation.chef.min_persons_quantity
      this.setState({ fields })
    }
  }

  handleSubmit = (event: any) => {
    const { reservation, onNextButtonClicked } = this.props
    const { fields } = this.state
    event.preventDefault()

    fields.adults = this.setMinPersonsQuantity(fields.adults)

    if (this.handleValidation()) {
      const reservationDate = `${fields['startDate'].getFullYear()}-${(
        '0' +
        (fields['startDate'].getMonth() + 1)
      ).slice(-2)}-${('0' + fields['startDate'].getDate()).slice(-2)} ${fields[
        'hour'
      ].getHours()}:${fields['hour'].getMinutes()}:00`
      onNextButtonClicked({
        reserved_at: reservationDate,
        chef_reservation_type_id: fields['type'].value,
        adults: fields['adults'],
      })
      GtmEventsLoad(new ChefEvent('checkout', 'checkout', 'chef').initialize({items: reservation}))
    }
  }

  setMinPersonsQuantity = (personsQuantity: number): number =>
    personsQuantity == 0 ? this.props.reservation.chef.min_persons_quantity : personsQuantity

  handleValidation = (): boolean => {
    const { chef } = this.props.reservation
    const { fields } = this.state
    let errors: any = {}
    let formIsValid = true

    if (!fields['startDate']) {
      formIsValid = false
      errors['startDate'] = 'La fecha es requerida.'
    }
    if (!fields['hour']) {
      formIsValid = false
      errors['hour'] = 'La hora es requerida.'
    }
    if (!fields['type']) {
      formIsValid = false
      errors['type'] = 'El tipo de evento es requerido.'
    }
    if (fields['adults'] > chef.persons_quantity) {
      formIsValid = false
      errors['limit'] = 'Has superado el número máximo de asistentes.'
    }
    if (fields['adults'] < chef.min_persons_quantity) {
      formIsValid = false
      errors['limit'] = `La cantidad mínima de asistentes es ${chef.min_persons_quantity}.`
    }
    this.setState({ errors })
    return formIsValid
  }

  render() {
    const { reservation, onBackButtonClicked, logo } = this.props
    const { fields, errors, reservationTypes, currentBookedDates, currentDate } = this.state

    return (
      <div className="step-into">
        <form onSubmit={this.handleSubmit.bind(this)} className="first-block">
          <Steps currentStep={1} />
          <div className="first-block-input-data">
            <div className="input-group">
              <label className="roboto lbl">Fecha</label>
              <DatePicker
                selected={fields['startDate']}
                onChange={(selectedDate) =>
                  this.setState({ fields: { ...fields, startDate: selectedDate } })
                }
                minDate={currentDate}
                todayButton={'Hoy'}
                dateFormat="dd-MM-yyyy"
                placeholderText="dd-mm-aaaa"
                className={`roboto-light input datepicker-style ${
                  errors['startDate'] ? 'error' : ''
                }`}
                locale="es"
                excludeDates={currentBookedDates}
              />
              <i className="icon-grande-table-24 input-icon" />
              {errors['startDate'] && <StyledError>{errors['startDate']}</StyledError>}
            </div>
            <div className="input-group">
              <label className="roboto lbl">Hora</label>
              <DatePicker
                selected={fields['hour']}
                onChange={(selectedHour) =>
                  this.setState({ fields: { ...fields, hour: selectedHour } })
                }
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Hora"
                dateFormat="HH:mm"
                placeholderText="hh:mm"
                className={`roboto-light input datepicker-style ${errors['hour'] ? 'error' : ''}`}
                locale="es"
              />
              <i className="icon-grande-table-30 input-icon" />
              {errors['hour'] && <StyledError>{errors['hour']}</StyledError>}
            </div>
            <div className="input-group sized">
              <label className="roboto lbl">Tipo de evento</label>
              <Select
                isSearchable={false}
                className={`select-control-container ${errors['type'] ? 'error' : ''}`}
                classNamePrefix="select-control"
                onChange={(currentType) =>
                  this.setState({ fields: { ...fields, type: currentType } })
                }
                error={errors['type']}
                value={fields['type']}
                options={reservationTypes}
                placeholder="Selecciona el tipo de evento"
              />
              {errors['type'] && <StyledError>{errors['type']}</StyledError>}
            </div>
            <div className="input-group wide">
              <label className="roboto lbl">Asistentes</label>
              <div className="row no-side-margins">
                <div className="numeric-input-container left-numeric-input-padding">
                  <NumericInputContainer>
                    <NumericInput
                      componentClass="input"
                      mobile
                      min={reservation.chef.min_persons_quantity}
                      max={reservation.chef.persons_quantity}
                      value={fields['adults']}
                      onChange={(value) => this.setState({ fields: { ...fields, adults: value } })}
                    />
                  </NumericInputContainer>
                  {errors['limit'] && (
                    <div className="input-group wide left-message">
                      <StyledError>{errors['limit']}</StyledError>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <p className="roboto-medium lbl text-center font-size-13 warning">
              {`Recuerda que el número máximo de asistentes es de ${
                reservation.chef.persons_quantity
              } ${reservation.chef.persons_quantity > 1 ? 'personas' : 'persona'}.`}
              <br />
              Beneficio exclusivo socios Grande Table.
            </p>
          </div>
          <div className="buttons-container">
            <button className="button-width next sfprotext font-size-13" type="submit">
              Siguiente
            </button>
            <Button
              className="button-width prev sfprotext font-size-13"
              link=""
              text="Regresar"
              callback={onBackButtonClicked}
              type="none"
            />
          </div>
        </form>
        <div className="second-block">
          <div className="second-block-summary">
            <div className="roboto-bold lbl">RESUMEN DE LA RESERVA</div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Chef</span>
              <span className="value roboto-bold">{reservation.chef.name}</span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Menú</span>
              <span className="value roboto-bold">{reservation.package.description}</span>
            </div>
          </div>
          <div className="gt-logo">
            <div className="roboto-light font-size-13">*Exclusivo para socios</div>
            <img src={logo} />
          </div>
        </div>
      </div>
    )
  }
}

export default Step1
