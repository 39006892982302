import React, { Component } from 'react'
import {
  FilterTitle,
  CheckboxContainer,
  CustomLabel,
  ViewMoreContainer
} from '../../../RestaurantsSection/components/RestaurantFilters/style'
import { Specialty } from '../../../Chef/chef'

class ChefFilters extends Component<
  {
    applyFilters: (specialty: Specialty, isChecked: boolean) => void
    specialties: [Specialty]
    filters: [string]
    isModal: boolean
  },
  any
> {
  constructor(props: any) {
    super(props)
    this.state = {
      minDataToShow: 5
    }
  }

  showCheckBox = (specialty: Specialty, isChecked: boolean) => (
    <CustomLabel>
      <input
        type="checkbox"
        value={specialty.name}
        onChange={this.handleOptions}
        checked={isChecked}
      />
      {specialty.name}
      <span className="checkmark" />
    </CustomLabel>
  )

  handleOptions = (e: any) => this.props.applyFilters(e.target.value, e.target.checked)

  handleViewMore = (groupSize: number) =>
    this.setState({ minDataToShow: this.state.minDataToShow == 5 ? groupSize : 5 })

  render() {
    const { specialties, filters, isModal } = this.props
    const { minDataToShow } = this.state

    return (
      <div>
        <FilterTitle className={isModal ? 'mobile' : ''}>
          <div className="row">
            <i className="icon-grande-table-15" />
            <div className="padded-title">ESPECIALIDAD</div>
          </div>
        </FilterTitle>
        <CheckboxContainer>
          {specialties.slice(0, minDataToShow).map((specialty: Specialty, index: number) => {
            for (const filter of filters) {
              if (specialty.name === filter) {
                return (
                  <div key={index}>
                    <div>{this.showCheckBox(specialty, true)}</div>
                  </div>
                )
                break
              }
            }
            return (
              <div className="checkbox" key={index}>
                {this.showCheckBox(specialty, false)}
              </div>
            )
          })}
        </CheckboxContainer>
        <ViewMoreContainer onClick={() => this.handleViewMore(specialties.length)}>
          {minDataToShow === 5 ? '+ Ver más' : '- Ver menos'}
        </ViewMoreContainer>
      </div>
    )
  }
}

export default ChefFilters
