import styled from 'styled-components'
import media from '../../../../styles/media'
import theme from '../../../../styles/theme'

export const ChefCardContainer = styled.div`
  margin: 20px auto;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.12);
  max-width: 280px;
  .picture {
    padding: 10px;
    .image-cropper {
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      text-align: center;
      width: auto;
      max-width: 268px;
      max-height: 191px;
    }
    .image {
      display: inline;
      margin: 0 auto;
      height: 100%;
      width: auto;
    }
    .restaurant-name {
      background-color: ${theme.secondaryColor};
      color: #ffffff;
      height: 32px;
      text-align: center;
      font-family: 'DroidSerif';
      font-size: 14px;
      line-height: 2.35;
      text-transform: capitalize;
      ${media.sm`
      margin: 0 3.5px;
      `}
      ${media.md`
      margin: 0;
      font-size: 13px;
      `}
      ${media.lg`
      margin: 0 1px;
      font-size: 16px;
      line-height: 1.9;
      `}
      .restaurant-link {
        vertical-align: middle;
        color: inherit;
        text-decoration: none;
        padding-right: 15px;
        ${media.sm`
        padding-right: 5px;
        `}
        ${media.lg`
        padding-right: 13px;
        `}
        &:hover {
          text-decoration: underline;
        }
      }
      span {
        &.no-link {
          vertical-align: middle;
        }
      }
    }
  }
  .data {
    padding: 0 15px;

    .info {
      letter-spacing: 1px;
      text-transform: capitalize;
      i {
        display: inline-block;
        vertical-align: top;
        width: 30px;
        font-size: 18px;
        padding-right: 8px;
      }
      span {
        display: inline-block;
        vertical-align: sub;
        width: 88%;
      }
    }
  }

  .button-container {
    text-align: center;
    width: 100%;
    display: block;
    a {
      margin: auto;
      display: block;
      max-width: 115px;
      ${media.lg`
      max-width: 125px;
      `}
    }
  }

  ${media.sm`
  margin: 0 20px 20px 0;
  max-width: 250px;
  flex: 0 0 50%;
  .picture {
    .image-cropper {
      height: 150px;
    }
  }
  .data {
    min-height: 100px;
    padding: 0 12px;
    .droid-serif-16 {
      font-size: 14px;
    }
    .info {
      font-size: 12px;
      span {
        width: 86%;
      }
    }
  }
  `}

  ${media.md`
  max-width: 222px;
  flex: 0 0 33.33333%;
  .data {
    .info {
      span {
        width: 84%;
      }
    }
  }
  `}

  ${media.lg`
  max-width: 290px;
  .picture {
    .image-cropper {
      width: 268px;
      height: 191px;
    }
  }
  .data {
    .droid-serif-16 {
      font-size: 16px;
    }
    .info {
      font-size: 13px;
      span {
        width: 88%;
      }
    }
  }
  `}
`
export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 0 0.5rem;
  margin: auto;
`