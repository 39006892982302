import React, { Component } from 'react'
import { Link } from 'gatsby'
import { Chef, Specialty, ChefRestaurant } from '../chef'

class InfoConditions extends Component<{ chef: Chef; className: string }, any> {
  render() {
    const { chef, className } = this.props
    return (
      <div className={className}>
        <div className="padding-bottom-20">
          <div className="padding-10-20 background-dark-gray text-color-black">
            Información chef
          </div>
          <div className="background-light-gray text-color-black">
            <ul className="padding-10-20">
              <li>
                <div className="item">
                  <i className="icon-grande-table-26" />
                  <span className="roboto-medium text">Ciudad</span>
                  <div className="roboto-light text value">{chef.city.name}</div>
                </div>
              </li>
              <li>
                <div className="item">
                  <i className="icon-grande-table-15" />
                  <span className="roboto-medium text">Especialidad</span>
                  {chef.specialties.map((specialty: Specialty, key: number) => (
                    <div key={key} className="roboto-light text value">
                      {specialty.name}
                    </div>
                  ))}
                </div>
              </li>
              <li>
                <div className="item">
                  <i className="icon-grande-table-03" />
                  <span className="roboto-medium text">Restaurante</span>
                  <div className="roboto-light text value capitalize">
                    {chef.restaurants && chef.restaurants.length > 0
                      ? chef.restaurants.map((restaurant: ChefRestaurant, index: number) => (
                          <Link
                            key={index}
                            className="restaurant-link"
                            to={`restaurants/${restaurant.slug}`}
                          >
                            {restaurant.name.charAt(0).toUpperCase() +
                              restaurant.name.slice(1).toLowerCase()}
                          </Link>
                        ))
                      : ''}
                    {chef.restaurant_name
                      ? chef.restaurant_name
                          .split(';')
                          .map(
                            (restaurant: string) =>
                              restaurant.charAt(0).toUpperCase() + restaurant.slice(1).toLowerCase()
                          )
                      : ''}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="padding-bottom-20">
          <div className="padding-10-20 background-dark-gray text-color-black">
            Condiciones para la reserva
          </div>
          <div className="background-light-gray text-color-black">
            <ul className="padding-10-20">
              <li>
                <div className="item">
                  <i className="icon-icon-multi" />
                  <span className="roboto-medium text">
                    Mínimo {chef.min_persons_quantity} menús
                  </span>
                </div>
              </li>
              <li>
                <div className="item">
                  <i className="icon-icon-multi" />
                  <span className="roboto-medium text">Máximo {chef.persons_quantity} menús</span>
                </div>
              </li>
              <li>
                <div className="item">
                  <i className="icon-grande-table-24" />
                  <span className="roboto-medium text">Reserva</span>
                  <div className="roboto-light text value">
                    {chef.reservationTime} días de anticipación
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default InfoConditions
