import React, { Component } from 'react'
import { ModalContainerProcess } from '../../RestaurantProfile/components/Reservation/Modals/style'
import { Picture } from '../style'
import Button from '../../Button'

const check = require('../../../assets/images/general/check.jpg')

export default class DoneMessage extends Component<{ closeModal: any }, any> {
  constructor(props: any) {
    super(props)
  }

  render() {
    const { closeModal } = this.props
    return (
      <ModalContainerProcess className="container">
        <div className="close-btn">
          <i className="icon-grande-table-21 close" onClick={closeModal} />
        </div>
        <Picture className="padding-bottom-20">
          <div className="image-cropper rectangle">
            <img className="margin-0" src={check} />
          </div>
        </Picture>
        <div className="lbl-text roboto-bold" style={{ fontSize: '20px', paddingBottom: '10px' }}>
          ¡Listo!
        </div>
        <div
          className="roboto-light lbl-text padding-46"
          style={{ fontSize: '13px', letterSpacing: '1px', paddingBottom: '15px' }}
        >
          Tu solicitud está siendo revisada, en el transcurso de 24 horas nos pondremos en contacto
          contigo.
        </div>
        <Button className="btn-width" link="chefs" text="Aceptar" type="primary" />
      </ModalContainerProcess>
    )
  }
}
