import React, { Component } from 'react'
import AuthContext from '../../../context/auth-context'
import Modal from 'react-modal'
import { Reservation } from '../chef'
import Steps from './steps'
import { Picture } from '../style'
import { StyledCheckBox, StyledError } from '../../Collapse/stepped/style'
import Button from '../../Button'
import DoneMessage from './done'
import { navigate } from 'gatsby'
import { ChefEvent } from '../../../helpers/GTM/ChefEvent'
import { GtmEventsLoad } from '../../../helpers/gtm'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    paddingLeft: 0,
    paddingRight: 0,
    width: '560px',
    textAlign: 'center',
  },
}

class Step5 extends Component<
  {
    logo: string
    reservation: Reservation
    formatDate: any
    formatTime: any
    onBackButtonClicked: any
    onNextButtonClicked: any
  },
  any
> {
  constructor(props: any) {
    super(props)
    this.state = {
      fields: {},
      errors: {},
      isOpened: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.setTotals()
  }

  formattedNumber = (numberValue: any) =>
    numberValue ? Number(numberValue).toFixed(2).replace('.', ',') : '0,00'

  setTotals = () => {
    const { reservation } = this.props
    let fields = this.state.fields
    fields['persons'] = reservation.kids + reservation.adults
    fields['subtotal'] = reservation.package.price * fields['persons']
    fields['tax'] = fields['subtotal'] * 0.12
    fields['total'] = fields['subtotal'] + fields['tax']
    this.setState({ fields })
  }

  handleUpdate = (field: string, event: any) => {
    let fields = this.state.fields
    fields[field] = event.target.checked
    this.setState({ fields })
  }

  handleSubmit = (event: any) => {
    const { onNextButtonClicked } = this.props
    const { fields } = this.state
    event.preventDefault()
    if (this.handleValidation()) {
      onNextButtonClicked({
        subtotal: fields['subtotal'],
        tax: fields['tax'],
        total: fields['total'],
      })
      this.openModal()
      window.scrollTo(0, 0)
      GtmEventsLoad(new ChefEvent('purchase', 'purchase', 'chef').initialize({items: this.props.reservation, purchaseFields: fields}))
    }
  }

  handleValidation = (): boolean => {
    const { fields } = this.state
    let errors: any = {}
    let formIsValid = true
    if (!fields['terms'] || fields['terms'] == false) {
      formIsValid = false
      errors['terms'] = 'Debes aceptar los términos y condiciones.'
    }
    this.setState({ errors })
    return formIsValid
  }

  openModal = () => this.setState({ isOpened: true })

  closeModal = () => this.setState({ isOpened: false }, () => navigate('chefs'))

  render() {
    const { user } = this.context
    const { reservation, formatDate, formatTime, logo, onBackButtonClicked } = this.props
    const { fields, errors } = this.state

    return (
      <div className="step-into">
        <div className="first-block">
          <Steps currentStep={5} />
          <div className="first-block-input-data last-step">
            <div className="droid lbl summary-title">Resumen de la reserva</div>
            <div className="greeting roboto font-size-13">
              <span className="roboto-bold">
                Hola {user ? user.name : reservation.invoice_data.invoice_name}
              </span>
              , tu experiencia culinaria está lista, por favor confirma que los datos a continuación
              sean correctos para finalizar el proceso de reserva.
            </div>
            <div className="picture-chef-package">
              <Picture className="picture">
                <div className="image-cropper">
                  <img
                    src={reservation.chef.picture}
                    alt={reservation.chef.name}
                    className="image"
                  />
                </div>
              </Picture>
              <div className="chef-package">
                <div className="title-name">{reservation.chef.name}</div>
                <div className="coverages-restrictions font-size-13">
                  <div className="roboto-medium text padding-bottom-submenu options-submenu">
                    {reservation.package.description}
                  </div>
                  <div className="roboto-thin text padding-bottom-6 italic">Incluye:</div>
                  <div className="roboto-light text padding-bottom-10">
                    {reservation.package.coverages}
                    <div className="roboto-bold text">
                      * Nos comunicaremos contigo para que conozcas todas las opciones de menú que
                      tiene el Chef.
                    </div>
                  </div>
                  <div className="roboto-thin text padding-bottom-6 italic">No Incluye:</div>
                  <div className="roboto-light text padding-bottom-10">
                    {reservation.package.restrictions}
                    <div className="roboto-bold text">
                      * Si requieres vajilla y bebidas tiene un costo adicional y el servicio deberá
                      ser confirmado en la llamada de confirmación de reserva.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="divider" />
            <div className="reservation-details">
              <ul>
                <li>
                  <i className="icon-icon-restaurant icon-cont" />
                  <div className="label-value">
                    <div className="roboto item-label">Tipo</div>
                    <div className="roboto-bold item-value">{reservation.type.name}</div>
                  </div>
                </li>
                <li>
                  <i className="icon-icon-table icon-cont" />
                  <div className="label-value">
                    <div className="roboto item-label">Asistentes</div>
                    {reservation.adults > 0 && (
                      <div className="roboto-bold item-value">
                        {reservation.adults} {`persona${reservation.adults > 1 ? 's' : ''}`}
                      </div>
                    )}
                  </div>
                </li>
                <li>
                  <i className="icon-icon-calendar icon-cont" />
                  <div className="label-value">
                    <div className="roboto item-label">Fecha</div>
                    <div className="roboto-bold item-value">
                      {formatDate(reservation.reserved_at)}
                    </div>
                  </div>
                </li>
                <li>
                  <i className="icon-icon-hours icon-cont" />
                  <div className="label-value">
                    <div className="roboto item-label">Hora</div>
                    <div className="roboto-bold item-value">
                      {formatTime(reservation.reserved_at)}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <hr className="divider" />
            <div className="place purple-brown font-size-13">
              <div className="roboto-medium place-title">LUGAR</div>
              <div className="roboto-light">{`${reservation.neighborhood}, ${reservation.main_street} ${reservation.secondary_street}, ${reservation.house_apt}, ${reservation.reference}`}</div>
            </div>
          </div>
        </div>
        <div className="second-block">
          <div className="second-block-summary">
            <div className="roboto-bold lbl">PRECOTIZACIÓN</div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Precio por persona</span>
              <span className="value roboto-bold">
                $ {this.formattedNumber(reservation.package.price)}
              </span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Asistentes</span>
              <span className="value roboto-bold">{`${fields['persons']} ${
                fields['persons'] > 1 ? 'personas' : 'persona'
              }`}</span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">SUBTOTAL</span>
              <span className="value roboto-bold">
                $ {this.formattedNumber(fields['subtotal'])}
              </span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">IVA</span>
              <span className="value roboto-bold">$ {this.formattedNumber(fields['tax'])}</span>
            </div>
            <hr className="divider" />
            <div className="summary-item total-item">
              <span className="roboto-bold">TOTAL</span>
              <span className="value roboto-bold">$ {this.formattedNumber(fields['total'])}</span>
            </div>
          </div>
          <div className="gt-logo">
            <div className="roboto-light font-size-13">*Exclusivo para socios</div>
            <img src={logo} />
          </div>
          <form className="terms-conditions-form" onSubmit={this.handleSubmit.bind(this)}>
            <div className="container terms-conditions">
              <StyledCheckBox className="text-center styled-chk">
                <span>{'Acepto '}</span>
                <a
                  href="https://grande-table.s3.amazonaws.com/terminos_y_condiciones_chef_en_casa.pdf"
                  target="_blank"
                  className="terms-conditions-link"
                >
                  términos y Condiciones
                </a>{' '}
                <input
                  type="checkbox"
                  checked={fields['terms']}
                  onChange={this.handleUpdate.bind(this, 'terms')}
                />
                <span className="checkmark" />
              </StyledCheckBox>
              {errors['terms'] && <StyledError>{errors['terms']}</StyledError>}
            </div>
            <div className="buttons-container">
              <button className="button-width next sfprotext font-size-13" type="submit">
                Enviar Solicitud
              </button>
              <Button
                className="button-width prev sfprotext font-size-13"
                link=""
                text="Regresar"
                callback={onBackButtonClicked}
                type="none"
              />
            </div>
          </form>
        </div>
        <Modal
          isOpen={this.state.isOpened}
          onRequestClose={() => this.closeModal}
          style={customStyles}
        >
          <DoneMessage closeModal={this.closeModal} />
        </Modal>
      </div>
    )
  }
}
Step5.contextType = AuthContext

export default Step5
