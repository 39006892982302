import styled from 'styled-components'
import media from '../../styles/media'

const bannerBg = require('../../assets/images/general/chefs-bg-rectangle.png')
const chefsBg = require('../../assets/images/pages/bg-restaurants.jpg')
const lightBrown = '#c59d5f'

export const ChefSectionContainer = styled.div`
  .main-image {
    padding: 30px 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .centered-text {
    text-align: center;
  }

  .font-size-15 {
    font-size: 15px;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .colored {
    color: ${lightBrown};
  }

  .sf-pro-text-regular {
    font-family: 'SFProText';
  }

  .sf-pro-text-semi-bold {
    font-family: 'SFProText Semibold';
  }

  .roboto-bold {
    font-family: 'Roboto Bold';
  }

  .roboto-light {
    font-family: 'Roboto Light';
  }

  .droid-serif-16 {
    font-family: 'DroidSerif';
    font-size: 16px;
  }

  .padding-bottom-20 {
    padding-bottom: 20px;
  }

  .padding-bottom-10 {
    padding-bottom: 10px;
  }

  .banner {
    background-image: url(${bannerBg});
    background-size: cover;
    padding: 30px 0;
    p {
      letter-spacing: 1.53px;
      margin-bottom: 0;
    }
    h2 {
      margin-bottom: 0;
      font-size: 34px;
      letter-spacing: 0.93px;
    }

    ${media.sm`
    padding: 50px 0;
    .first-b {
      display: inline-block;
      vertical-align: middle;
      width: 50%;
    }
    .second-b {
      display: inline-block;
      vertical-align: middle;
      width: 50%;
      .question {
        text-align: left;
        padding-left: 70px;
      }
    }
    `}
  }

  ul {
    counter-reset: li;
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      position: relative;
      padding-bottom: 20px;
      padding-left: 60px;
      text-align: left;
      &:before {
        content: counter(li);
        counter-increment: li;
        height: 30px;
        width: 30px;
        border: 1px solid ${lightBrown};
        border-radius: 50%;
        text-align: center;
        position: absolute;
        left: 15px;
        top: 4px;
        line-height: 27px;
      }
    }
  }

  .list-container {
    padding: 20px;
    position: relative;
    background-image: url(${chefsBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-position-y: bottom;
    ${media.md`  
    background-attachment: scroll;  
    min-height: 867px;
    `};

    .filter {
      margin: 0 auto;
      cursor: pointer;
      background-color: ${lightBrown};
      border: solid 0 ${lightBrown};
      border-radius: 5px;
      width: auto;
      max-width: 280px;
      &:hover {
        box-shadow: 0 0 24px 0 ${lightBrown};
      }
      .container-btn-modal {
        padding: 5px 10px;
        width: 100%;
        i {
          vertical-align: middle;
          font-size: 25px;
          padding-left: 10%;
          padding-right: 5%;
        }
        .filter-btn-txt {
          display: inline-block;
          vertical-align: middle;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 0.81px;
          text-align: right;
          padding-left: 6%;
        }
      }
      ${media.sm`
      display: none;
      `}
    }

    .filter-options {
      display: none;
      ${media.sm`
      display: inline-block;
      vertical-align: top;
      width: 25%;
      `}
      ${media.lg`
      width: 18%;
      `}
    }

    .chef-list {
      ${media.sm`
      display: inline-block;
      vertical-align: top;
      width: 75%;
      `}
      ${media.lg`
      width: 82%;
      `}
    }

    .padding-left-right-10 {
      margin: 0 10px;
    }

    ${media.sm`
    .padding-top-bottom-45 {
      padding-top: 45px;
      padding-bottom: 45px;
    }
    `}
  }
`
