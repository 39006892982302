import React, { Component } from 'react'
import AuthContext from '../../../context/auth-context'
import { Reservation } from '../chef'
import Steps from './steps'
import { StyledError } from '../../Collapse/stepped/style'
import Button from '../../Button'
import { ChefEvent } from '../../../helpers/GTM/ChefEvent'
import { GtmEventsLoad } from '../../../helpers/gtm'
import { validateIdentification } from '../../../utils/validateIdentification'

class Step4 extends Component<
  {
    logo: string
    reservation: Reservation
    formatDate: any
    formatTime: any
    onBackButtonClicked: any
    onNextButtonClicked: any
    customerId: number
  },
  any
> {
  constructor(props: any) {
    super(props)
    this.state = {
      fields: {},
      errors: {}
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.setInputValues()
  }

  setInputValues = () => {
    const { user } = this.context
    const { reservation, customerId } = this.props
    const { fields } = this.state
    if (reservation) {
      fields['customerId'] = customerId
      fields['name'] = reservation.invoice_data
        ? `${reservation.invoice_data.invoice_name} ${reservation.invoice_data.invoice_last_name}`
        : user
        ? `${user.name} ${user.last_name}`
        : null
      fields['identification'] = reservation.invoice_data
        ? reservation.invoice_data.invoice_idn
        : user
        ? user.identification
        : null
      fields['phone'] = reservation.invoice_data
        ? reservation.invoice_data.invoice_phone
        : user
        ? user.phone
        : null
      fields['email'] = reservation.invoice_data
        ? reservation.invoice_data.invoice_email
        : user
        ? user.email
        : null
      fields['address'] = reservation.invoice_data
        ? reservation.invoice_data.address
        : user
        ? user.address
        : null
      this.setState({ fields })
    }
  }

  handleUpdate = (field: string, event: any) => {
    let fields = this.state.fields
    fields[field] = event.target.value
    this.setState({ fields })
  }

  handleSubmit = (event: any) => {
    const { onNextButtonClicked, reservation } = this.props
    const { fields } = this.state
    event.preventDefault()
    const nameTokens = fields['name'].trim().split(' ')
    const name = nameTokens[0]
    const lastName = nameTokens
      .map((token: string, index: number) => {
        if (index > 0) {
          return token
        }
      })
      .join(' ')
      .trim()
    if (this.handleValidation()) {
      onNextButtonClicked({
        customer_id: fields['customerId'],
        identification: fields['identification'],
        customer_name: name,
        customer_lastname: lastName,
        customer_phone: fields['phone'],
        customer_email: fields['email'],
        customer_address: fields['address']
      })
      GtmEventsLoad(new ChefEvent('checkout', 'checkout', 'chef').initialize({items: reservation, customerFields:fields}))
    }
  }

  handleValidation = (): boolean => {
    const { fields } = this.state
    let errors: any = {}
    let formIsValid = true
    if (!fields['name']) {
      formIsValid = false
      errors['name'] = 'El nombre es requerido.'
    }
    if (/[^ñáéíóúa-z _]/i.test(fields['name'])) {
      formIsValid = false
      errors['name'] = 'El nombre solo debe contener letras.'
    }
    if (!fields['identification']) {
      formIsValid = false
      errors['identification'] = 'La cédula de identidad es requerida.'
    } else {
      const validIdentification = validateIdentification(fields['identification'], 'C')
      if (validIdentification.error){
        errors['identification'] = validIdentification.message
        formIsValid = false
      }
    }
    if (!fields['phone']) {
      formIsValid = false
      errors['phone'] = 'El teléfono es requerido.'
    } else if (!/^[0-9]*$/.test(fields['phone'])) {
      formIsValid = false
      errors['phone'] = 'El teléfono solo debe contener números.'
    }
    if (!fields['address']) {
      formIsValid = false
      errors['address'] = 'La dirección es requerida.'
    }
    if (!fields['email']) {
      formIsValid = false
      errors['email'] = 'El correo electrónico es requerido.'
    }
    if (!/\S+@\S+\.\S+/.test(fields['email'])) {
      formIsValid = false
      errors['email'] = 'El correo electrónico es incorrecto.'
    }

    this.setState({ errors })
    return formIsValid
  }

  render() {
    const { reservation, logo, formatDate, formatTime, onBackButtonClicked } = this.props
    const { fields, errors } = this.state

    return (
      <div className="step-into">
        <form onSubmit={this.handleSubmit.bind(this)} className="first-block">
          <Steps currentStep={4} />
          <div className="first-block-input-data">
            <div className="input-group">
              <label className="roboto lbl">Nombre Socio Grande Table</label>
              <input
                name="name"
                value={fields['name'] || ''}
                className={`input ${errors['name'] ? 'error' : ''}`}
                type="text"
                onChange={this.handleUpdate.bind(this, 'name')}
              />
              {errors['name'] && <StyledError>{errors['name']}</StyledError>}
            </div>
            <div className="input-group">
              <label className="roboto lbl">Cédula de identidad</label>
              <input
                name="identification"
                value={fields['identification'] || ''}
                className={`input ${errors['identification'] ? 'error' : ''}`}
                type="text"
                onChange={this.handleUpdate.bind(this, 'identification')}
              />
              {errors['identification'] && <StyledError>{errors['identification']}</StyledError>}
            </div>
            <div className="input-group">
              <label className="roboto lbl">Teléfono de contacto</label>
              <input
                name="phone"
                value={fields['phone'] || ''}
                className={`input ${errors['phone'] ? 'error' : ''}`}
                type="text"
                onChange={this.handleUpdate.bind(this, 'phone')}
              />
              {errors['phone'] && <StyledError>{errors['phone']}</StyledError>}
            </div>
            <div className="input-group">
              <label className="roboto lbl">Correo electrónico</label>
              <input
                name="email"
                value={fields['email'] || ''}
                className={`input ${errors['email'] ? 'error' : ''}`}
                type="text"
                onChange={this.handleUpdate.bind(this, 'email')}
              />
              {errors['email'] && <StyledError>{errors['email']}</StyledError>}
            </div>
            <div className="input-group wide">
              <label className="roboto lbl">Dirección de facturación</label>
              <input
                name="address"
                value={fields['address'] || ''}
                className={`input ${errors['address'] ? 'error' : ''}`}
                type="text"
                onChange={this.handleUpdate.bind(this, 'address')}
              />
              {errors['address'] && <StyledError>{errors['address']}</StyledError>}
            </div>
          </div>
          <div className="buttons-container">
            <button className="button-width next sfprotext font-size-13" type="submit">
              Siguiente
            </button>
            <Button
              className="button-width prev sfprotext font-size-13"
              link=""
              text="Regresar"
              callback={onBackButtonClicked}
              type="none"
            />
          </div>
        </form>
        <div className="second-block">
          <div className="second-block-summary">
            <div className="roboto-bold lbl">RESUMEN DE LA RESERVA</div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Chef</span>
              <span className="value roboto-bold">{reservation.chef.name}</span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Menú</span>
              <span className="value roboto-bold">{reservation.package.description}</span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Tipo</span>
              <span className="value roboto-bold">{reservation.type.name}</span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Asistentes</span>
              <span className="value roboto-bold">{`${reservation.adults + reservation.kids} ${
                reservation.adults + reservation.kids > 1 ? 'personas' : 'persona'
              }`}</span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Fecha</span>
              <span className="value roboto-bold">{formatDate(reservation.reserved_at)}</span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Hora</span>
              <span className="value roboto-bold">{formatTime(reservation.reserved_at)}</span>
            </div>
          </div>
          <div className="gt-logo">
            <div className="roboto-light font-size-13">*Exclusivo para socios</div>
            <img src={logo} />
          </div>
        </div>
      </div>
    )
  }
}
Step4.contextType = AuthContext

export default Step4
