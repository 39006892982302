import React, { Component } from 'react'
import { StyledContainer } from './style'
import Step1 from './step-1'
import Step2 from './step-2'
import Step3 from './step-3'
import { Reservation } from '../chef'
import Step4 from './step-4'
import Step5 from './step-5'

const logo = require('../../../assets/images/general/logo.svg')

class BookingLayout extends Component<
  {
    reservation: Reservation
    bookedDates: [Reservation]
    disableDays: number
    currentStep: number
    onBackButtonClicked: any
    onNextButtonClicked: any
    customerId: number
  },
  any
> {
  constructor(props: any) {
    super(props)
    this.state = {
      isOpened: false
    }
  }

  formatDate = (dateAsString: string) => {
    let date = new Date(dateAsString),
      month = '' + (date.getMonth() + 1),
      day = '' + date.getDate(),
      year = date.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [day, month, year].join('-')
  }

  formatTime = (dateAsString: string) => {
    let date = new Date(dateAsString),
      hour = '' + date.getHours(),
      minute = '' + date.getMinutes()

    if (hour.length < 2) hour = '0' + hour
    if (minute.length < 2) minute = '0' + minute

    return [hour, minute].join('h')
  }

  selectStep = () => {
    const {
      reservation,
      bookedDates,
      disableDays,
      currentStep,
      onBackButtonClicked,
      onNextButtonClicked,
      customerId
    } = this.props
    switch (currentStep) {
      case 1:
        return (
          <Step1
            logo={logo}
            reservation={reservation}
            bookedDates={bookedDates}
            disableDays={disableDays}
            onBackButtonClicked={onBackButtonClicked}
            onNextButtonClicked={onNextButtonClicked}
          />
        )
      case 2:
        return (
          <Step2
            logo={logo}
            reservation={reservation}
            onBackButtonClicked={onBackButtonClicked}
            onNextButtonClicked={onNextButtonClicked}
            formatDate={this.formatDate}
            formatTime={this.formatTime}
          />
        )
      case 3:
        return (
          <Step3
            logo={logo}
            reservation={reservation}
            formatDate={this.formatDate}
            formatTime={this.formatTime}
            onBackButtonClicked={onBackButtonClicked}
            onNextButtonClicked={onNextButtonClicked}
          />
        )
      case 4:
        return (
          <Step4
            logo={logo}
            reservation={reservation}
            formatDate={this.formatDate}
            formatTime={this.formatTime}
            onBackButtonClicked={onBackButtonClicked}
            onNextButtonClicked={onNextButtonClicked}
            customerId={customerId}
          />
        )
      case 5:
        return (
          <Step5
            logo={logo}
            reservation={reservation}
            formatDate={this.formatDate}
            formatTime={this.formatTime}
            onBackButtonClicked={onBackButtonClicked}
            onNextButtonClicked={onNextButtonClicked}
          />
        )
    }
  }

  render() {
    const { onBackButtonClicked } = this.props
    return (
      <StyledContainer>
        <div className="container back-btn roboto" onClick={onBackButtonClicked}>
          <i className="icon-grande-table-13" />
          Regresar
        </div>
        <div className="container">{this.selectStep()}</div>
      </StyledContainer>
    )
  }
}

export default BookingLayout
