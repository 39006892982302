import styled from 'styled-components'
import media from '../../../styles/media'
import theme from '../../../styles/theme'

const bannerBg = require('../../../assets/images/general/chefs-bg-rectangle.png')
const camelColor = theme.camel
const forwardStepColor = '#595959'
const grayColor = '#959595'
const lineColor = '#c1c1c1'
const inputBorder = theme.veryLightPink
const iconColor = theme.blue
const dividerColor = '#dfdfdf'
const greenColor = '#5bc15b'

export const StyledContainer = styled.div`
  background-image: url(${bannerBg});
  background-size: 100% 144px;
  background-repeat: no-repeat;

  ${media.sm`
  background-size: 100% 242px;
  `}
  
  .roboto {
    font-family: 'Roboto';
  }

  .roboto-medium {
    font-family: 'Roboto Medium';
  }

  .roboto-light {
    font-family: 'Roboto Light';
  }

  .roboto-bold {
    font-family: 'Roboto Bold';
  }

  .italic {
    padding-bottom: 6px;
    font-style: italic;
  }

  .sfprotext {
    font-family: 'SFProText';
  }

  .droid {
    font-family: 'DroidSerif';
  }

  .font-size-13 {
    font-size: 13px;
  }

  .no-side-margins {
    margin-left: 0;
    margin-right: 0:
  }

  .text-center {
    text-align: center;
  }

  .width-90 {
    width: 90px;
  }

  .back-btn {
    cursor: pointer;
    padding-top: 30px;
    color: ${camelColor};
    letter-spacing: 1.08px;
  }

  .step-zone {    
    .step {
      padding: 0 0 60px;
      .step-container {
        text-align: center;
      }
      .step-padding {
        display: inline-block;
        vertical-align: top;
        padding: 10px;
        &:first-child {
          padding-left: 0;
        }
        &:nth-last-child(2) {
          padding-right: 0;
        }
        ${media.md`
        padding: 15px;
        `}
      }
      .step-group {
        width: 42px;
        ${media.sm`
        width: 68px;
        `}
        ${media.md`
        width: 82px;
        `}
        div {
          margin: auto;
        }
      }
      .step-item {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        text-align: center;
        padding-top: 6px;
        font-size: 14.4px;
      }
      .current-step {
        background-color: ${camelColor};
      }
      .forward-step {
        background-color: ${forwardStepColor};
        color: ${grayColor};
      }
      .done-step {
        background-color: #FFFFFF;
        .green {
          color: ${greenColor};
        }
      }
      .step-name {
        padding-top: 5px;
        font-size: 10px;
        letter-spacing: 1.08px;
        word-wrap: break-word;
        line-height: 9px;
        ${media.sm`
        font-size: 12px;
        `}
        ${media.md`
        padding-top: 8px;
        line-height: 12px;
        `}
      }
      .thin-line {
        margin-top: -50px;
        width: 75%;
        height: 1px;
        border: none;
        background-color: ${lineColor};
        ${media.md`
        margin-top: -52px;
        width: 75%;
        `}
        ${media.lg`
        width: 55%;
        `}
      }
    }
  }

  .step-into {
    .first-block {
      padding: 20px 0;
      .first-block-input-data {
        margin-bottom: 20px;
        padding: 15px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
        ${media.lg`
        &.last-step {
          padding: 30px 50px;
        }
        `}
      }
      ${media.sm`
      display: inline-block;
      width: 65%;
      padding-right: 20px;
      vertical-align: top;
      `}
      ${media.lg`
      width: 70%;
      `}
    }

    .second-block {
      .second-block-summary {
        margin-bottom: 20px;
        padding: 20px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
      }
      ${media.sm`
      display: inline-block;
      padding-top: 120px;
      width: 35%;
      `}
      ${media.lg`
      width: 30%;
      .second-block-summary {
        padding: 20px 30px;
      }
      `}
    }
  }

  .input-group {
    padding-bottom: 15px;
    position: relative;
    ${media.md`
    padding: 3px 15px 15px;
    display: inline-block;
    width: 50%;
    &.sized {
      width: 50%;
    }
    &.wide {
      width: 100%;
      &.left-message {
        padding-left: 0;
      }
    }
    `}
    ${media.lg`
    padding: 5px 25px 25px;
    `}
  }

  .lbl {
    color: #000000;
  }

  .purple-brown {
    color: ${theme.purpleBrown};
  }

  .radio-container {
    width: 100%;
    label {
      padding-right: 30px;
    }
  }

  .input {
    border: solid 1px ${inputBorder};
    border-radius: 2px;
    max-height: 40px;
    width: 100%;
    padding: 15px 10px;
    
    &.error {
      border: solid 1px red;
    }

    &[type=radio] {
      display: none;
    }
    &[type=radio] + label {
      cursor:pointer;
    }
    &[type=radio] + label:before {
      content: "";
      display:inline-block;
      width:15px;
      height:15px;
      padding: 3px;
      margin-right: 10px;
      border: solid 1px ${inputBorder};
      border-radius:50%;
      vertical-align: text-top;
      transition: 0.3s;
    }
    &[type=radio]:checked + label:before {
      background: ${camelColor};
      background-clip: content-box;
    }
  }

  .datepicker-style {
    background-color: transparent;
  }

  i.input-icon {
    position: absolute;
    top: 37px;
    right: 10px;
    font-size: 16px;
    color: ${iconColor};
    ${media.sm`
    right: 10px;
    `}
    ${media.md`
    right: 20px;
    top: 40px;
    `}
    ${media.lg`
    right: 30px;
    top: 42px;
    `}
  }

  .react-datepicker-time__header {
    font-family: 'Roboto Light';
    color: #ffffff;
    letter-spacing: 1.08px;
  }

  .select-control-container {
    .select-control__control {
      border-radius: 2px;
      &.select-control__control--is-focused {
        border: solid 1px ${inputBorder};
        box-shadow: none;
      }
    }
    .select-control__menu {
      color: #000000;
    }
    .select-control__indicators {
      div {
        color: ${iconColor};
      }
      span {
        background-color: transparent;
      }
    }
    &.error {
      .select-control__control {
        border: solid 1px red;
      }
    }
  }

  .numeric-input-container {
    label{
      padding-top: 10px;
    }
  }

  .left-numeric-input-padding {
    padding-right: 40px;
  }

  p.warning {
    padding: 0 20px;
    letter-spacing: 1px;
    color: ${theme.purpleBrown};
    ${media.sm`
    padding: 0 40px 20px;
    `}
  }

  .buttons-container {
    text-align: center;
    .button-width {
      width: 70%;
      max-height: 35px;
      &.next {
        margin-bottom: 20px;
        outline: none;
        background-color: ${camelColor};
        border: solid 1px ${camelColor};
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 17.5px;
        text-transform: uppercase;
        letter-spacing: 0.75px;
        &:hover {
          background-color: #FFFFFF;
          color: ${camelColor};
        }
      }
      &.prev {
        border-color: #000000;
        color: #000000;
        &:hover {
          background-color: #000000;
          color: #FFFFFF;
        }
      }
    }
    ${media.sm`
    direction: rtl;
    .button-width {
      display: inline-block;
      width: 35%;
      &:last-child {
        margin-right: 20px;
      }
    }
    `}
  }

  .divider {
    height: 1px;
    border: none;
    background-color: ${dividerColor};
  }

  .summary-item {
    padding-top: 5px;
    padding-bottom: 5px;
    span {
      color: #000000;
      &.value {
        float: right;
        letter-spacing: 1.08px;
      }
    }
  }

  .gt-logo {
    padding-bottom: 20px;
    text-align: center;
    div {
      letter-spacing: 1px;
      color: ${theme.purpleBrown};
      padding-bottom: 8px;
    }
    img {
      color: #000000;
    }
  }

  .summary-title {
    font-size: 20px;
    padding-bottom: 20px;
  }

  .greeting {
    color: ${theme.purpleBrown};
  }

  .picture-chef-package {
    .chef-package {
      .title-name {
        color: ${camelColor};
        padding-bottom: 20px;
        font-family: DroidSerif;
        font-size: 30px;
        text-align: center;
      }
    }
    ${media.sm`
    padding-top: 20px;
    .picture {
      display: inline-block;
      padding: 0;
      width: 40%;
    }
    .chef-package {
      display: inline-block;
      width: 60%;
      .title-name {
        font-size: 20px;
        text-align: left;
        padding-bottom: 10px;
      }
    }
    `}
    ${media.md`
    .picture {
      width: 25%;
      padding-right: 30px;
    }
    .chef-package {
      width: 75%;
    }
    `}
  }

  .coverages-restrictions {
    color: ${theme.purpleBrown};
    letter-spacing: 1px;
    .padding-bottom-submenu{
      padding-bottom: 10px;
    }
    .padding-bottom-6 {
      padding-bottom: 6px;
    }
    .padding-bottom-10 {
      padding-bottom: 10px;
    }
  }

  .reservation-details {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin-left: 0;
        margin-bottom: 10px;
        padding: 0;
        display: inline-block;
        vertical-align: top;
        width: 100%;

        ${media.sm`
        text-align: center;
        width: 50%;
        margin-bottom: 0;
        padding: 15px 10px;
        `}

        ${media.md`
        width: 25%;
        `}

        .icon-cont {
          vertical-align: middle;
          font-size: 53px;
          color: #000000;
          margin-right: 15px;
          i:before {
            margin: 0;
          }
          ${media.sm`
          display: block;
          margin-right: 0;
          `}
        }
        .label-value {
          display: inline-block;
          vertical-align: middle;
          .item-label {
            color: #000000;
            padding-bottom: 6px;
          }
          .item-value {
            color: ${theme.purpleBrown};
            letter-spacing: 1.08px;

          }
          ${media.sm`
          display: block;
          `}
        }
      }
    }
  }

  .place {
    padding: 15px;
    letter-spacing: 1px;
    .place-title {
      padding-bottom: 20px;
    }
  }

  .total-item {
    font-size: 18px;
    letter-spacing: 1.38px;
    span {
      color: ${camelColor};
    }
  }

  .terms-conditions-form {
    padding-top: 20px;
    padding-bottom: 20px;
    
    .terms-conditions {
      padding-bottom: 20px;
      span {
        vertical-align: middle;
      }
      .terms-conditions-link {
        color: ${camelColor};
        text-decoration: underline;
        vertical-align: middle;
      }
      label {
        .checkmark {
          left: 20px;
        }
      }
      ${media.sm`
      label {
        text-align: center;
        width: 100%;
        .checkmark {
          left: 2px;
        }
      }
      `}
      ${media.md`
      label {
        .checkmark {
          left: 45px;
        }
      }
      `}
    }

    .buttons-container {
      direction: unset;
      .button-width {
        width: 70%;
        ${media.sm`
        :first-child {
          width: 85%;
        }
        :last-child {
          max-width: 200px;
          margin-right: 0;
        }
        `}
      }
    }
  }
`
