import React, { Component } from 'react'
import { RouteComponentProps } from '@reach/router'
import axios from 'axios'
import Modal from 'react-modal'
import AuthContext from '../../context/auth-context'
import Button from '../Button'
import Loading from '../Loading'
import { ChefContainer, Picture, BookingContainer } from './style'
import FrecuentlyAskedQuestions from './components/faq'
import InfoConditions from './components/info-conditions'
import { ModalContainerProcess } from '../RestaurantProfile/components/Reservation/Modals/style'
import Identification from './components/identification'
import Membership from './components/membership'
import BookingLayout from './booking'
import { Package, Reservation } from './chef'
import { PipeReplacer } from '../Generics/PipeReplacer'
import { GtmEventsLoad } from '../../helpers/gtm'
import { ChefEvent } from '../../helpers/GTM/ChefEvent'

const Arrow = (props: any) => {
  const { onClick, icon, className } = props
  return (
    <div className={className} onClick={onClick}>
      <i className={`icon-grande-table-${icon} font-18`} />
    </div>
  )
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    paddingLeft: 0,
    paddingRight: 0,
  },
}

const modalIdentificationStyles = {
  content: { ...customStyles.content, width: '750px' },
}

const membershipModalStyles = {
  content: { ...customStyles.content, width: '600px' },
}

class Chef extends Component<RouteComponentProps<{ slug: string }>, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      loading: true,
      chef: {},
      isIdentificationModalOpened: false,
      isMembershipModalOpened: false,
      showChefInfo: false,
      step: 0,
      reservation: {},
      selectedPackage: {},
      customerId: null,
    }
  }

  componentDidMount() {
    if (sessionStorage.getItem('user')) {
      const loggedUser = JSON.parse(sessionStorage.getItem('user') || '')
      if (loggedUser.customerId) {
        this.setState({
          customerId: loggedUser.customerId,
        })
      }
    }

    this.getChef(this.props.slug || '')
  }

  getChef = (slug: string) =>
    axios
      .get(`${process.env.API_URL}chefs/${slug}`)
      .then((data: any) => data.data)
      .then((response: any) => {
        this.setState({
          chef: {
            id: response.id,
            slug: response.slug,
            name: response.name,
            picture: response.picture,
            description: response.description,
            packages: response.packages,
            restaurants: response.restaurants,
            restaurant_name: response.restaurant_name,
            city: response.city,
            specialties: response.specialties,
            persons_quantity: response.persons_quantity,
            min_persons_quantity: response.min_persons_quantity,
            reservationTime: response.reservation_time,
            reservations: response.reservations,
          },
          loading: false,
          showChefInfo: true,
        })
      })

  formattedNumber = (numberValue: any) =>
    numberValue ? Number(numberValue).toFixed(2).replace('.', ',') : '0,00'

  startBooking = (selectedPackage: Package /*, customerId: number*/) => {
    let { chef, reservation, step } = this.state
    reservation = {
      chef_id: chef.id,
      chef_package_id: selectedPackage.id,
      //customerId,
      customerId: null,
    }
    this.saveBooking(reservation, step)
    GtmEventsLoad(new ChefEvent('addToCart', 'add', 'chef').initialize({items: chef}))
  }

  saveBooking = (reservation: Reservation, step: number) => {
    const headers = {
      'Content-Type': 'application/json',
    }
    axios
      .post(`${process.env.API_URL}chefs/${this.props.slug}/reservation/${step}`, reservation, {
        headers,
      })
      .then((data: any) => data.data)
      .then((response: any) =>
        this.setState({ reservation: response, step: this.setNextStep(step), showChefInfo: false })
      )
  }

  setNextStep = (currentStep: number) => {
    return currentStep + 1 > 5 ? 5 : currentStep + 1
  }

  // openIdentificationModal = (selectedPackage: Package) =>
  //   this.setState({ selectedPackage, isIdentificationModalOpened: true })

  // closeIdentificationModal = (closingType: string) => {
  //   switch (closingType) {
  //     case 'normal':
  //       this.setState({ isIdentificationModalOpened: false })
  //       return
  //     case 'booking':
  //       this.setState({ isIdentificationModalOpened: false })
  //       return
  //     case 'membership':
  //       this.setState({ isIdentificationModalOpened: false, isMembershipModalOpened: true })
  //       return
  //   }
  // }

  // closeMembershipModal = () => this.setState({ isMembershipModalOpened: false })

  // retrieveCustomerId = (customerId: number) =>
  //   this.setState({ customerId }, () =>
  //     this.startBooking(this.state.selectedPackage, this.state.customerId)
  //   )

  onNextButtonClicked = (fields: any) => {
    let { step, reservation } = this.state
    reservation = { ...reservation, ...fields }
    this.setState({ reservation })
    this.saveBooking(reservation, step)
  }

  onBackButtonClicked = () => {
    let { step } = this.state
    step = step - 1
    this.setState({ showChefInfo: step == 0 ? true : false, step })
  }

  render() {
    const { user } = this.context
    const {
      loading,
      chef,
      isIdentificationModalOpened,
      isMembershipModalOpened,
      showChefInfo,
      reservation,
      step,
      customerId,
    } = this.state

    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      autoplay: true,
      autoplaySpeed: 2500,
      slidesToShow: 4,
      slidesToScroll: 1,
      pauseOnHover: true,
      arrows: true,
      nextArrow: <Arrow icon="14" className="next" />,
      prevArrow: <Arrow icon="13" className="prev" />,
      responsive: [
        {
          breakpoint: 1999,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 450,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: true,
          },
        },
        {
          breakpoint: 420,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
          },
        },
      ],
    }

    let isLoggedIn = sessionStorage.getItem('access_token')

    return loading === true ? (
      <Loading />
    ) : (
      <div>
        <ChefContainer className={`container ${showChefInfo ? '' : 'hide'}`}>
          <div className="left-panel">
            <div className="picture-secondblock">
              <Picture>
                <div className="image-cropper">
                  <img src={chef.picture} alt={chef.name + '-Grande Table'} className="image" />
                </div>
              </Picture>
              <div className="secondblock">
                <div
                  className="title-name"
                  dangerouslySetInnerHTML={{ __html: PipeReplacer({ pipedString: chef.name }) }}
                />
                <div className="roboto-light text padding-bottom-20">{chef.description}</div>
              </div>
            </div>
            <hr className="thin-line" />
            <div className="roboto-bold text padding-bottom-20 font-14 options-title">
              OPCIONES DE MENÚ
            </div>
            {chef.packages.map((packageItem: Package, index: number) => (
              <div key={index} className="padding-20 option-container">
                <div className="coverages-restrictions">
                  <div className="roboto-medium text padding-bottom-20 options-submenu">
                    {packageItem.description}
                  </div>
                  <div className="roboto-medium text padding-bottom-20 italic">
                    Menú por persona incluye:
                  </div>
                  <div className="padding-bottom-20">
                    {packageItem.coverages.map((coverage: string, index: number) => (
                      <div key={index} className="roboto-light text">
                        {coverage}
                      </div>
                    ))}
                    <div className="roboto-bold text font-11">
                      * Nos comunicaremos contigo para que conozcas todas las opciones de menú que
                      tiene el Chef.
                    </div>
                  </div>
                  <div className="roboto-medium text padding-bottom-20 italic">
                    Servicio incluye:
                  </div>
                  <div className="padding-bottom-20">
                    {packageItem.services.map((service: string, index: number) => (
                      <div key={index} className="roboto-light text">
                        {service}
                      </div>
                    ))}
                  </div>
                  <div className="roboto-medium text padding-bottom-20 italic">
                    Servicio No incluye:
                  </div>
                  <div className="padding-bottom-20">
                    {packageItem.restrictions.map((restriction: string, index: number) => (
                      <div key={index} className="roboto-light text">
                        {restriction}
                      </div>
                    ))}
                    <div className="roboto-bold text font-11">
                      * Si requieres vajilla y bebidas tiene un costo adicional y el servicio deberá
                      ser confirmado en la llamada de confirmación de reserva.
                    </div>
                  </div>
                </div>
                <div className="roboto text colored centered-text">
                  Costo por persona
                  <div className="roboto-bold font-18 cost">
                    USD$ {this.formattedNumber(packageItem.price)}
                  </div>
                  <div className="margin-width">
                    <Button
                      className="black-border text-color-black"
                      link=""
                      text="Reservar"
                      callback={
                        () =>
                          // isLoggedIn ?
                          this.startBooking(packageItem /*, user.customerId*/)
                        //   : this.openIdentificationModal(packageItem)
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
            <InfoConditions className="mobile" chef={chef} />
            {/* <div className="padding-bottom-20">
              <div className="roboto-bold text font-14 padding-bottom-10">PREGUNTAS FRECUENTES</div>
              <FrecuentlyAskedQuestions />
            </div> */}
          </div>
          <div className="right-panel">
            <InfoConditions className="web" chef={chef} />
          </div>
          {/* <Modal
            isOpen={isIdentificationModalOpened}
            onRequestClose={() => this.closeIdentificationModal('normal')}
            style={modalIdentificationStyles}
          >
            <ModalContainerProcess className="container">
              <div className="row close-btn">
                <i
                  className="icon-grande-table-21 close"
                  onClick={() => this.closeIdentificationModal('normal')}
                />
              </div>
              <Identification
                closeIdentificationModal={this.closeIdentificationModal}
                retrieveCustomerId={this.retrieveCustomerId}
              />
            </ModalContainerProcess>
          </Modal>
          <Modal
            isOpen={isMembershipModalOpened}
            onRequestClose={() => this.closeMembershipModal()}
            style={membershipModalStyles}
          >
            <ModalContainerProcess className="container membership">
              <div className="row close-btn">
                <i
                  className="icon-grande-table-21 close"
                  onClick={() => this.closeMembershipModal()}
                />
              </div>
              <Membership />
            </ModalContainerProcess>
          </Modal> */}
        </ChefContainer>
        <BookingContainer className={`${showChefInfo ? 'hide' : ''}`}>
          <BookingLayout
            reservation={reservation}
            bookedDates={chef.reservations}
            disableDays={chef.reservationTime}
            currentStep={step}
            onBackButtonClicked={this.onBackButtonClicked}
            onNextButtonClicked={this.onNextButtonClicked}
            customerId={customerId}
          />
        </BookingContainer>
      </div>
    )
  }
}
Chef.contextType = AuthContext

export default Chef
