import React, { Component } from 'react'
import * as NumericInput from 'react-numeric-input'
import Steps from './steps'
import { StyledError } from '../../Collapse/stepped/style'
import { Reservation } from '../chef'
import { NumericInputContainer } from '../../Generics/IncreaseDecreaseNumber/style'
import Button from '../../Button'
import { GtmEventsLoad } from '../../../helpers/gtm'
import { ChefEvent } from '../../../helpers/GTM/ChefEvent'

class Step3 extends Component<
  {
    logo: string
    reservation: Reservation
    formatDate: any
    formatTime: any
    onBackButtonClicked: any
    onNextButtonClicked: any
  },
  any
> {
  constructor(props: any) {
    super(props)
    this.state = {
      fields: {
        kitchen_type_1: false,
        kitchen_type_2: false
      },
      errors: {}
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.setInputValues()
  }

  setInputValues = () => {
    const { reservation } = this.props
    const { fields } = this.state
    if (reservation) {
      fields['kitchen_type_1'] = reservation.kitchen_type == 'Gas' || false
      fields['kitchen_type_2'] = reservation.kitchen_type == 'Inducción' || false
      fields['allergies'] = reservation.allergies || null
      fields['vegetarianPeople'] = reservation.vegetarian_people || 0
      this.setState({ fields })
    }
  }

  handleUpdate = (field: string, event: any) => {
    let fields = this.state.fields
    fields[field] = event.target.value
    this.setState({ fields })
  }

  handleCheck = (kitchenType: string) => {
    let fields = this.state.fields
    this.setState({
      fields: {
        ...fields,
        kitchen_type_1: kitchenType == 'kitchen_type_1',
        kitchen_type_2: kitchenType == 'kitchen_type_2'
      }
    })
  }

  handleSubmit = (event: any) => {
    const { reservation, onNextButtonClicked } = this.props
    const { fields } = this.state
    event.preventDefault()
    if (this.handleValidation()) {
      onNextButtonClicked({
        kitchen_type: fields['kitchen_type_1'] ? 'Gas' : 'Inducción',
        allergies: fields['allergies'],
        vegetarian_people: fields['vegetarianPeople']
      })
      GtmEventsLoad(new ChefEvent('checkout', 'checkout', 'chef').initialize({items: reservation}))
    }
  }

  handleValidation = (): boolean => {
    const { fields } = this.state
    let errors: any = {}
    let formIsValid = true
    if (!fields['kitchen_type_1'] && !fields['kitchen_type_2']) {
      formIsValid = false
      errors['kitchen'] = 'El tipo de cocina es requerido.'
    }
    this.setState({ errors })
    return formIsValid
  }

  render() {
    const { reservation, logo, formatDate, formatTime, onBackButtonClicked } = this.props
    const { fields, errors } = this.state

    return (
      <div className="step-into">
        <form onSubmit={this.handleSubmit.bind(this)} className="first-block">
          <Steps currentStep={3} />
          <div className="first-block-input-data">
            <div className="input-group">
              <label className="roboto lbl">¿Qué tipo de cocina tiene?</label>
              <div className="radio-container">
                <input
                  className="input radio"
                  type="radio"
                  name="kitchen_type_1"
                  value="Gas"
                  checked={fields['kitchen_type_1']}
                  id="type_1"
                  onClick={this.handleCheck.bind(this, 'kitchen_type_1')}
                />
                <label className="roboto-light lbl" htmlFor="type_1">
                  Gas
                </label>
                <input
                  className="input radio"
                  type="radio"
                  name="kitchen_type_2"
                  value="Inducción"
                  checked={fields['kitchen_type_2']}
                  id="type_2"
                  onClick={this.handleCheck.bind(this, 'kitchen_type_2')}
                />
                <label className="roboto-light lbl" htmlFor="type_2">
                  Inducción
                </label>
              </div>
              {errors['kitchen'] && <StyledError>{errors['kitchen']}</StyledError>}
            </div>
            <div className="input-group">
              <label className="roboto lbl">¿Tiene algún tipo de alergias?</label>
              <input
                name="allergies"
                value={fields['allergies'] || ''}
                className={`input ${errors['allergies'] ? 'error' : ''}`}
                type="text"
                onChange={this.handleUpdate.bind(this, 'allergies')}
              />
              {errors['allergies'] && <StyledError>{errors['allergies']}</StyledError>}
            </div>
            <div className="input-group">
              <label className="roboto lbl">¿Hay personas vegetarianas?</label>
              <NumericInputContainer>
                <NumericInput
                  componentClass="input"
                  mobile
                  min={0}
                  max={15}
                  value={fields['vegetarianPeople']}
                  onChange={value =>
                    this.setState({ fields: { ...fields, vegetarianPeople: value } })
                  }
                />
              </NumericInputContainer>
              <label className="roboto lbl text-center width-90">Vegetarianos</label>
            </div>
          </div>
          <div className="buttons-container">
            <button className="button-width next sfprotext font-size-13" type="submit">
              Siguiente
            </button>
            <Button
              className="button-width prev sfprotext font-size-13"
              link=""
              text="Regresar"
              callback={onBackButtonClicked}
              type="none"
            />
          </div>
        </form>
        <div className="second-block">
          <div className="second-block-summary">
            <div className="roboto-bold lbl">RESUMEN DE LA RESERVA</div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Chef</span>
              <span className="value roboto-bold">{reservation.chef.name}</span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Menú</span>
              <span className="value roboto-bold">{reservation.package.description}</span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Tipo</span>
              <span className="value roboto-bold">{reservation.type.name}</span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Asistentes</span>
              <span className="value roboto-bold">{`${reservation.adults + reservation.kids} ${
                reservation.adults + reservation.kids > 1 ? 'personas' : 'persona'
              }`}</span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Fecha</span>
              <span className="value roboto-bold">{formatDate(reservation.reserved_at)}</span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Hora</span>
              <span className="value roboto-bold">{formatTime(reservation.reserved_at)}</span>
            </div>
          </div>
          <div className="gt-logo">
            <div className="roboto-light font-size-13">*Exclusivo para socios</div>
            <img src={logo} />
          </div>
        </div>
      </div>
    )
  }
}

export default Step3
