import React, { Component } from 'react'
import axios from 'axios'
import Select from 'react-select'
import { City } from '../../MembershipForm'
import Steps from './steps'
import { StyledError } from '../../Collapse/stepped/style'
import Button from '../../Button'
import { Reservation } from '../chef'
import { GtmEventsLoad } from '../../../helpers/gtm'
import { ChefEvent } from '../../../helpers/GTM/ChefEvent'

class Step2 extends Component<
  {
    logo: string
    reservation: Reservation
    onBackButtonClicked: any
    onNextButtonClicked: any
    formatDate: any
    formatTime: any
  },
  any
> {
  constructor(props: any) {
    super(props)
    this.state = {
      cities: [],
      fields: {},
      errors: {}
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getCities()
    this.setInputValues()
  }

  getCities = () => {
    const headers = {
      'Content-Type': 'application/json'
    }
    axios
      .post(`${process.env.API_URL}cities-by-module`, { chef: true }, { headers })
      .then((data: any) => data.data)
      .then((response: any) => this.setState({ cities: response }))
  }

  setInputValues = () => {
    const { reservation } = this.props
    const { fields } = this.state
    if (reservation) {
      fields['city'] = reservation.city
        ? { value: reservation.city.id, label: reservation.city.name }
        : null
      fields['neighborhood'] = reservation.neighborhood || null
      fields['mainStreet'] = reservation.main_street || null
      fields['houseApt'] = reservation.house_apt || null
      fields['secondaryStreet'] = reservation.secondary_street || null
      fields['reference'] = reservation.reference || null
      this.setState({ fields })
    }
  }

  handleUpdate = (field: string, event: any) => {
    let fields = this.state.fields
    fields[field] = event.target.value
    this.setState({ fields })
  }

  handleSubmit = (event: any) => {
    const { reservation, onNextButtonClicked } = this.props
    const { fields } = this.state
    event.preventDefault()
    if (this.handleValidation()) {
      onNextButtonClicked({
        city_id: fields['city'].value,
        neighborhood: fields['neighborhood'],
        main_street: fields['mainStreet'],
        house_apt: fields['houseApt'],
        secondary_street: fields['secondaryStreet'],
        reference: fields['reference']
      })
      GtmEventsLoad(new ChefEvent('checkout', 'checkout', 'chef').initialize({items: reservation}))
    }
  }

  handleValidation = (): boolean => {
    const { fields } = this.state
    let errors: any = {}
    let formIsValid = true
    if (!fields['city']) {
      formIsValid = false
      errors['city'] = 'La ciudad es requerida.'
    }
    if (!fields['neighborhood']) {
      formIsValid = false
      errors['neighborhood'] = 'El sector es requerido.'
    }
    if (!fields['mainStreet']) {
      formIsValid = false
      errors['mainStreet'] = 'La calle principal es requerida'
    }
    if (!fields['houseApt']) {
      formIsValid = false
      errors['houseApt'] = 'El número de casa o departamento.'
    }
    if (!fields['secondaryStreet']) {
      formIsValid = false
      errors['secondaryStreet'] = 'La calle secundaria es requerida.'
    }
    if (!fields['reference']) {
      formIsValid = false
      errors['reference'] = 'La referencia es requerida.'
    }
    this.setState({ errors })
    return formIsValid
  }

  render() {
    const { reservation, onBackButtonClicked, logo, formatDate, formatTime } = this.props
    const { cities, fields, errors } = this.state

    return (
      <div className="step-into">
        <form onSubmit={this.handleSubmit.bind(this)} className="first-block">
          <Steps currentStep={2} />
          <div className="first-block-input-data">
            <div className="input-group">
              <label className="roboto lbl">Ciudad</label>
              <Select
                isSearchable={false}
                className={`select-control-container ${errors['city'] ? 'error' : ''}`}
                classNamePrefix="select-control"
                onChange={currentCity =>
                  this.setState({ fields: { ...fields, city: currentCity } })
                }
                options={(() => {
                  const options = cities.map((city: City) => ({
                    value: city.id,
                    label: city.name
                  }))
                  return options
                })()}
                error={errors['city']}
                value={fields['city']}
                placeholder="Selecciona la ciudad"
              />
              {errors['city'] && <StyledError>{errors['city']}</StyledError>}
            </div>
            <div className="input-group">
              <label className="roboto lbl">Sector</label>
              <input
                name="neighborhood"
                value={fields['neighborhood'] || ''}
                className={`input ${errors['neighborhood'] ? 'error' : ''}`}
                type="text"
                onChange={this.handleUpdate.bind(this, 'neighborhood')}
              />
              {errors['neighborhood'] && <StyledError>{errors['neighborhood']}</StyledError>}
            </div>
            <div className="input-group">
              <label className="roboto lbl">Calle principal</label>
              <input
                name="mainStreet"
                value={fields['mainStreet'] || ''}
                className={`input ${errors['mainStreet'] ? 'error' : ''}`}
                type="text"
                onChange={this.handleUpdate.bind(this, 'mainStreet')}
              />
              {errors['mainStreet'] && <StyledError>{errors['mainStreet']}</StyledError>}
            </div>
            <div className="input-group">
              <label className="roboto lbl">Número de casa/dept</label>
              <input
                name="houseApt"
                value={fields['houseApt'] || ''}
                className={`input ${errors['houseApt'] ? 'error' : ''}`}
                type="text"
                onChange={this.handleUpdate.bind(this, 'houseApt')}
              />
              {errors['houseApt'] && <StyledError>{errors['houseApt']}</StyledError>}
            </div>
            <div className="input-group">
              <label className="roboto lbl">Calle secundaria</label>
              <input
                name="secondaryStreet"
                value={fields['secondaryStreet'] || ''}
                className={`input ${errors['secondaryStreet'] ? 'error' : ''}`}
                type="text"
                onChange={this.handleUpdate.bind(this, 'secondaryStreet')}
              />
              {errors['secondaryStreet'] && <StyledError>{errors['secondaryStreet']}</StyledError>}
            </div>
            <div className="input-group">
              <label className="roboto lbl">Referencia</label>
              <input
                name="reference"
                value={fields['reference'] || ''}
                className={`input ${errors['reference'] ? 'error' : ''}`}
                type="text"
                onChange={this.handleUpdate.bind(this, 'reference')}
              />
              {errors['reference'] && <StyledError>{errors['reference']}</StyledError>}
            </div>
          </div>
          <div className="buttons-container">
            <button className="button-width next sfprotext font-size-13" type="submit">
              Siguiente
            </button>
            <Button
              className="button-width prev sfprotext font-size-13"
              link=""
              text="Regresar"
              callback={onBackButtonClicked}
              type="none"
            />
          </div>
        </form>
        <div className="second-block">
          <div className="second-block-summary">
            <div className="roboto-bold lbl">RESUMEN DE LA RESERVA</div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Chef</span>
              <span className="value roboto-bold">{reservation.chef.name}</span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Menú</span>
              <span className="value roboto-bold">{reservation.package.description}</span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Tipo</span>
              <span className="value roboto-bold">{reservation.type.name}</span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Asistentes</span>
              <span className="value roboto-bold">{`${reservation.adults + reservation.kids} ${
                reservation.adults + reservation.kids > 1 ? 'personas' : 'persona'
              }`}</span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Fecha</span>
              <span className="value roboto-bold">{formatDate(reservation.reserved_at)}</span>
            </div>
            <hr className="divider" />
            <div className="summary-item">
              <span className="roboto">Hora</span>
              <span className="value roboto-bold">{formatTime(reservation.reserved_at)}</span>
            </div>
          </div>
          <div className="gt-logo">
            <div className="roboto-light font-size-13">*Exclusivo para socios</div>
            <img src={logo} />
          </div>
        </div>
      </div>
    )
  }
}

export default Step2
