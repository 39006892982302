import React, { Component } from 'react'

class Steps extends Component<{ currentStep: number }, any> {
  render() {
    const { currentStep } = this.props
    const steps = [
      { step: 1, stepName: 'EVENTO' },
      { step: 2, stepName: 'LUGAR' },
      { step: 3, stepName: 'ADICIONALES' },
      { step: 4, stepName: 'SOCIO' },
      { step: 5, stepName: 'RESUMEN' },
    ]
    return (
      <div className="step-zone">
        <div className="step">
          <div className="step-container">
            {steps.map((stepItem: any, index: number) => {
              if (stepItem.step == currentStep) {
                return (
                  <div key={index} className="step-padding">
                    <div className="step-group">
                      <div className="step-item current-step">{stepItem.step}</div>
                      <div className="step-name">{stepItem.stepName}</div>
                    </div>
                  </div>
                )
              }
              if (stepItem.step < currentStep) {
                return (
                  <div key={index} className="step-padding">
                    <div className="step-group">
                      <div className="step-item done-step">
                        <i className="icon-icon-chek-plus green" />
                      </div>
                      <div className="step-name">{stepItem.stepName}</div>
                    </div>
                  </div>
                )
              }
              return (
                <div key={index} className="step-padding">
                  <div className="step-group">
                    <div className="step-item forward-step">{stepItem.step}</div>
                    <div className="step-name">{stepItem.stepName}</div>
                  </div>
                </div>
              )
            })}
            <hr className="thin-line" />
          </div>
        </div>
      </div>
    )
  }
}

export default Steps
