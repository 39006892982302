import { IPageEvent, PageEventProps } from './IPageEvent'

export class ChefEvent implements IPageEvent {
  private readonly TypeEvent: string
  private readonly ActionField: string
  private readonly GroupActionEvent: string

  constructor(typeEvent: string, actionField: string, groupActionEvent: string) {
    this.TypeEvent = typeEvent
    this.ActionField = actionField
    this.GroupActionEvent = groupActionEvent
  }

  initialize({
    items,
    customerFields,
    purchaseFields,
  }: {
    items: any
    customerFields?: any
    purchaseFields?: any
  }): PageEventProps {
    const { step } = items

    return {
      event: this.TypeEvent,
      [this.TypeEvent]: {
        [this.GroupActionEvent]: {
          actionField: {
            step,
            list: this.ActionField,
          },
          ...this.getCustomerChef(items, customerFields),
          ...this.getPurchaseChef(items, purchaseFields),
          ...this.getChef(items),
        },
      },
    }
  }

  getCustomerChef(items: any, customerFields: any): any {
    const customerData = customerFields || items.invoice_data
    if (customerData) {
      const {
        id,
        customerId,
        identification,
        name,
        email,
        invoice_idn,
        invoice_name,
        invoice_last_name,
        phone,
        invoice_phone,
        invoice_email,
      } = customerData

      return {
        customer: {
          customer_id: customerId || id,
          identification: identification || invoice_idn,
          name: name || invoice_name + ' ' + invoice_last_name,
          mobile: phone || invoice_phone,
          email: email || invoice_email,
        },
      }
    }
  }

  getPurchaseChef(items: any, purchaseFields?: any): any {
    if (items.package) {
      return {
        purchase: {
          quantity: items.adults,
          price: items.package.price,
          tax: purchaseFields?.tax || items.tax,
          subtotal: purchaseFields?.subtotal || items.subtotal,
          total: purchaseFields?.total || items.total,
        },
      }
    }
  }

  getChef(items: any): any {
    const { chef } = items
    if (chef) {
      const { id, name, slug } = chef
      return {
        item: { id, name, slug },
      }
    }
    return {
      item: {
        id: items.id,
        name: items.name,
        slug: items.slug,
      },
    }
  }
}
