import React, { Component } from 'react'
import { navigate, Link } from 'gatsby'
import { LinkContainer } from '../../../EventsSection/components/style'
import { Chef, Specialty, ChefRestaurant } from '../../../Chef/chef'
import { ChefCardContainer, ButtonContainer } from './style'
import { PipeReplacer } from '../../../Generics/PipeReplacer'
import { GtmEventsLoad } from '../../../../helpers/gtm'
import { ChefEvent } from '../../../../helpers/GTM/ChefEvent'

class ChefCard extends Component<{ chef: Chef }, any> {
  render() {
    const { chef } = this.props

    const openChef = () => {
      GtmEventsLoad(new ChefEvent('chefClick', 'click', 'chef').initialize({items: chef}))
      navigate('/chefs/' + chef.slug)
    }

    return (
      <ChefCardContainer>
        <div className="picture padding-bottom-10">
          <div className="image-cropper">
            <img src={chef.picture} alt={chef.name + '-Grande Table'} className="image" />
          </div>
          <div className="restaurant-name">
            {chef.restaurants && chef.restaurants.length > 0
              ? chef.restaurants.map((restaurant: ChefRestaurant, index: number) => (
                  <Link
                    key={index}
                    className="restaurant-link"
                    to={`restaurants/${restaurant.slug}`}
                  >
                    {restaurant.name.charAt(0).toUpperCase() +
                      restaurant.name.slice(1).toLowerCase()}
                  </Link>
                ))
              : ''}
            {chef.restaurant_name
              ? chef.restaurant_name.split(';').map((restaurant: string, index: number) => (
                  <span key={index} className="no-link">
                    {restaurant.charAt(0).toUpperCase() + restaurant.slice(1).toLowerCase()}
                  </span>
                ))
              : ''}
          </div>
        </div>
        <div className="data">
          <div
            className="colored padding-bottom-10 droid-serif-16"
            dangerouslySetInnerHTML={{ __html: PipeReplacer({ pipedString: chef.name }) }}
          />
          <div className="info padding-bottom-10 roboto-light">
            <i className="icon-grande-table-15" />
            <span>{chef.specialties.map((specialty: Specialty) => specialty.name).join(', ')}</span>
          </div>
        </div>
        <ButtonContainer 
          className="col-6 button-container padding-bottom-10"
          onClick={openChef}
        >
          <LinkContainer type="secondary">Ver más</LinkContainer>
        </ButtonContainer>
      </ChefCardContainer>
    )
  }
}

export default ChefCard
