import { Router } from '@reach/router'
import { withPrefix } from 'gatsby'
import React from 'react'
import Chef from '../components/Chef'
import ChefsSection from '../components/ChefsSection'
import Layout from '../components/Layout'
export default () => (
  <Layout themeType="dark" currentPage={'chefs'}>
    <Router>
      <ChefsSection path={withPrefix('/chefs')} />
      <Chef path={withPrefix('/chefs/:slug')} />
    </Router>
  </Layout>
)
